// ** Icon imports
// import DashboardIcon from "@mui/icons-material/Dashboard";
// import WalletIcon from "@mui/icons-material/Wallet";
// import DataThresholdingIcon from "@mui/icons-material/DataThresholding";
// import CreditCardIcon from "@mui/icons-material/CreditCard";
// import AlignVerticalBottomIcon from "@mui/icons-material/AlignVerticalBottom";
// import TuneIcon from "@mui/icons-material/Tune";
// import LogoutIcon from "@mui/icons-material/Logout";
// import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";
import OverViewIcon from "../../@core/component/icons/overViewIcon";
// import ActivitySidebarIcon from "../../@core/component/icons/activityIcon";
import WalletIcon from "../../@core/component/icons/walletIcon";
import PortfolioIcon from "../../@core/component/icons/portfolioIcon";
import Activityicon from "../../@core/component/icons/activityIcon";
import SafenoteIcon from "../../@core/component/icons/safenoteIcon";
import Settingsicon from "../../@core/component/icons/settingsIcon";
import CommentIcon from "../../@core/component/icons/commentsIcon";
import SubscriptionIcon from "../../@core/component/icons/subscriptionIcon";

// import OverviewIcon from "../../assets/images/overViewSidebarIcon.svg";
// import WalletIcon from "../../assets/images/walletSidebarIcon.svg";
// import PortfolioIcon from "../../assets/images/portfolioSidebarIcon.svg";
// import SafeNoteIcon from "../../assets/images/safeNoteSidebarIcon.svg";
// import ActivityIcon from "../../assets/images/activitySidebarIcon.svg";
// import CommentIcon from "../../assets/images/commentSidebarIcon.svg";
// import SettingsIcon from "../../assets/images/settingsSidebardIcon.svg";
// import SubscriptionIcon from "../../assets/images/subscriptionSidebarIcon.svg";
// import LogoutIcon from "../../assets/images/LogoutSidebarIcon.svg";

const navigation = () => {
  return [
    {
      icon: OverViewIcon,
      title: "Overview",
      path: "/dashboard/main",
    },
    {
      icon: WalletIcon,
      title: "Wallet",
      path: "/wallet/main",
    },
    {
      icon: PortfolioIcon,
      title: "Portfolio",
      path: "/holdings/main",
    },
    {
      icon: SafenoteIcon,
      title: "Company List",
      path: "/safenote/main",
    },
    // {
    //   icon: DataThresholdingIcon,
    //   title: "Seller",
    //   path: "/seller",
    // },
    // {
    //   icon: CreditCardIcon,
    //   title: 'Payment',
    //   path: '/payment'
    // },
    {
      icon: Activityicon,
      title: "Activity",
      path: "/activity/main",
    },
    // {
    //   icon: TextsmsOutlinedIcon,
    //   title: "Comment",
    //   path: "/comment",
    // },
    {
      icon: CommentIcon,
      title: "Comment",
      path: "/comment",
    },
    // {
    //   icon: TextsmsOutlinedIcon,
    //   title: "CommentV2",
    //   path: "/commentV2",
    // },
    {
      icon: Settingsicon,
      title: "Settings",
      path: "/settings/main",
    },
    {
      icon: SubscriptionIcon,
      title: "Services",
      path: "/coming-soon",
    },
    // {
    //   icon: LogoutIcon,
    //   title: "Logout",
    //   // path: "/logout",
    // },
  ];
};

export default navigation;
