import React, { Suspense, useEffect, useState } from "react";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import "./App.css";

import FallbackSpinner from "./@core/component/spinner";
import AuthGuard from "./@core/component/auth/AuthGuard";
import UserLayout from "./layouts/UserLayout";

import { onMessage } from "firebase/messaging";
import { getNotificationPermission, messaging } from "./notification/firebase";
import { useAuth } from "./hooks/useAuth";
import { useSnackbar } from "notistack";
import { Avatar, Box, Typography } from "@mui/material";
import InboxImage from "./assets/images/inbox-mail.png";
import PublicLayout from "./layouts/publicLayout";

const CommentV2 = React.lazy(() => import("./pages/commentV2"));

const Home = React.lazy(() => import("./pages/home/index"));
const ComingSoon = React.lazy(() => import("./pages/comingsoon/index"));
const RegistrationPage = React.lazy(() => import("./pages/registration"));
const RegistrationDetails = React.lazy(() =>
  import("./pages/registration/registerDetails")
);
const OtpForm = React.lazy(() => import("./pages/registration/otpForm"));
const ForgotPassword = React.lazy(() => import("./pages/login/forgotPassword"));
const Login = React.lazy(() => import("./pages/login"));
const Dashboard = React.lazy(() => import("./pages/dashboard"));
const DashboardStockHoldingsDetails = React.lazy(() =>
  import("./pages/dashboard/holdingDetails")
);
const NoInternetConnection = React.lazy(() =>
  import("./components/503Page/index")
);
const NoPageFound = React.lazy(() => import("./components/404Page/index"));
const AboutUs = React.lazy(() =>
  import("./components/projectInfromationPage/aboutUs")
);
const CatagorySlider = React.lazy(() =>
  import("./components/dashboardComponent/catagoriesSlider")
);
const MonthlySubscriptionPackage = React.lazy(() =>
  import("./pages/monthlySubscription/monthlySubscription")
);
const MyProfile = React.lazy(() =>
  import("./pages/userProfile/myProfile/index")
);
const EditProfile = React.lazy(() =>
  import("./pages/userProfile/editUserProfile/index")
);
const ChangePassword = React.lazy(() =>
  import("./pages/userProfile/changePassword/index")
);
const CreateSeller = React.lazy(() =>
  import("./pages/sellerProfile/createSeller/index")
);
const EditSeller = React.lazy(() =>
  import("./pages/sellerProfile/createSeller/index")
);
const PolicyPage = React.lazy(() =>
  import("./components/projectInfromationPage/privacypolicy")
);
const TermsConditionsPage = React.lazy(() =>
  import("./components/projectInfromationPage/termsConditions")
);
const ContactUsPage = React.lazy(() =>
  import("./components/projectInfromationPage/contactUs")
);
const FaqPage = React.lazy(() =>
  import("./components/projectInfromationPage/faq")
);
const NotificationPage = React.lazy(() => import("./pages/notification"));
// const BuyerSellerView = React.lazy(() =>
//   import("./pages/buyeSellerProfileView")
// );
const WalletPage = React.lazy(() => import("./pages/walletPage"));
const PaymentPage = React.lazy(() => import("./pages/payment"));
const Holdings = React.lazy(() => import("./pages/holdings"));
const BuyHoldings = React.lazy(() =>
  import("./pages/holdings/personalHoldingDetails/personalHoldings")
);
const Settings = React.lazy(() => import("./pages/settings"));
const ActivityPage = React.lazy(() => import("./pages/activity"));
const SellerProfileView = React.lazy(() =>
  import("./pages/sellerProfile/veiwSellerProfile")
);
const PopUpBankDetails = React.lazy(() =>
  import("./components/addSellerDialog/popup/popupBankDetails")
);
const Seller = React.lazy(() => import("./pages/seller"));
const NDA = React.lazy(() => import("./pages/nda"));
const SellerList = React.lazy(() => import("./pages/sellerList"));
const Comment = React.lazy(() => import("./pages/comment"));
const InvestByCompanyDetails = React.lazy(() =>
  import("./components/investByCompany/investByCompanyDetails")
);
const SafeNote = React.lazy(() => import("./pages/safeNote"));
const PingPage = React.lazy(() => import("./pages/ping"));

// Version 2
const LandingPage = React.lazy(() => import("./version2/landingPage"));
const DashboardV2 = React.lazy(() =>
  import("./version2/userLayoutPages/dashboard")
);
const SellerListV2 = React.lazy(() => import("./version2/sellerList"));
const DashboardStockHoldingsDetailsV2 = React.lazy(() =>
  import("./version2/userLayoutPages/dashboard/holdingDetailsV2")
);
const WalletPageV2 = React.lazy(() => import("./version2/walletPage"));
const HoldingsV2 = React.lazy(() => import("./version2/holdings"));
const SafeNoteV2 = React.lazy(() => import("./version2/safeNote"));
const PersonalHoldingDetailsV2 = React.lazy(() =>
  import("./version2/holdings/personalHoldingDetails/personalHoldings")
);
const ActivityPageV2 = React.lazy(() => import("./version2/activity"));
const SellerV2 = React.lazy(() => import("./version2/seller"));
const SettingsV2 = React.lazy(() => import("./version2/settings"));
const InvestByCompanyDetailsV2 = React.lazy(() =>
  import("./version2/investByCompany/investByCompanyDetails")
);

function App() {
  const { user, getUnReadNotificationCount } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  console.log("DEBUG user", user);

  const [isTokenFound, setTokenFound] = useState(false);

  useEffect(() => {
    if (user) {
      getNotificationPermission(setTokenFound);
      onMessage(messaging, (payload) => {
        getUnReadNotificationCount();
        console.log("Message received.", payload);
        enqueueSnackbar(
          <Box display={"flex"} justifyContent={"space-between"} gap={1}>
            <Box>
              <Avatar
                src={InboxImage}
                alt={"InboxImage"}
                variant="square"
                sx={{ height: "30px", width: "30px" }}
              />
            </Box>
            <Box>
              <Typography color={"#000"}>
                {payload.notification.title}
              </Typography>
              <Typography color={"#000"}>
                {payload.notification.body}
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: "12px" }}>Just Now</Typography>
            </Box>
          </Box>,
          {
            style: {
              backgroundColor: "#fff",
              // height: "100%",
              border: "1px solid #007167",
              marginTop: "50px",
              borderRadius: "10px",
            },
            anchorOrigin: {
              horizontal: "right",
              vertical: "top",
            },
          }
        );
        // alert(payload.notification.title);
        // alert(payload.notification.body);
      });
    }
  }, [user]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    // <ThemeProvider theme={theme}>
    <div className="App">
      <Suspense fallback={<FallbackSpinner />}>
        <AuthGuard>
          <Routes>
            <Route path="/" element={<Navigate to="/landing-page" />}></Route>
            <Route
              element={
                <PublicLayout>
                  <Outlet />
                </PublicLayout>
              }
            >
              <Route path="/landing-page" element={<LandingPage />} />
            </Route>
            {/* <Route path='/home' element={<Home />}></Route> */}
            <Route
              path="/"
              element={
                <UserLayout>
                  <Outlet />
                </UserLayout>
              }
            >
              {/* <Route path="/dashboard" element={<Dashboard />} /> */}
              <Route
                path="/dashboard/:holdingID"
                element={<DashboardStockHoldingsDetails />}
              />
              <Route path="/wallet" element={<WalletPage />} />
              <Route path="/holdings" element={<Holdings />} />
              <Route path="/holdings/:tabId" element={<Holdings />} />
              <Route
                path="/holdings/:type/details/:holdingId"
                element={<BuyHoldings />}
              />
              <Route
                path="/holdings/details/:holdingId"
                element={<BuyHoldings />}
              />
              <Route path="/seller" element={<Seller />} />
              <Route path="/activity/seller/:tabId" element={<Seller />} />
              <Route path="/activity" element={<ActivityPage />} />
              <Route path="/payment" element={<PaymentPage />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/settings/:tabId" element={<Settings />} />

              <Route path="/create-seller-profile" element={<CreateSeller />} />
              <Route path="/edit-seller-profile" element={<EditSeller />} />
              <Route path="/seller-list" element={<SellerList />} />
              <Route path="/comment" element={<Comment />} />
              <Route
                path="/user-change-password"
                element={<ChangePassword />}
              />
              <Route path="/bank-details" element={<PopUpBankDetails />} />
              <Route path="/coming-soon" element={<ComingSoon />} />
              <Route
                path="/settings/:tabId/invest-by-company/details/:id"
                element={<InvestByCompanyDetails />}
              />

              {/* Comment V2 Started*/}
              <Route path="/commentV2" element={<CommentV2 />} />
              <Route path="/safenote" element={<SafeNote />} />
              <Route path="/safenote/:tabId" element={<SafeNote />} />

              {/* Comment V2 End*/}

              {/* THANAWI TRANSFORMATION ROUTES STARTED */}
              <Route path="/dashboard/main" element={<DashboardV2 />} />
              <Route
                path="/dashboard/main/:holdingID"
                element={<DashboardStockHoldingsDetailsV2 />}
              />
              <Route path="/sellerlist/main" element={<SellerListV2 />} />
              <Route path="/wallet/main" element={<WalletPageV2 />} />
              <Route path="/holdings/main" element={<HoldingsV2 />} />
              <Route path="/holdings/main/:tabId" element={<HoldingsV2 />} />
              <Route path="/safenote/main" element={<SafeNoteV2 />} />
              <Route path="/safenote/main/:tabId" element={<SafeNoteV2 />} />
              <Route
                path="/holdings/main/:type/details/:holdingId"
                element={<PersonalHoldingDetailsV2 />}
              />
              <Route path="/activity/main" element={<ActivityPageV2 />} />
              <Route
                path="/activity/main/seller/:tabId"
                element={<SellerV2 />}
              />
              <Route path="/settings/main" element={<SettingsV2 />} />
              <Route path="/settings/main/:tabId" element={<SettingsV2 />} />
              <Route
                path="/settings/main/:tabId/invest-by-company/details/:id"
                element={<InvestByCompanyDetailsV2 />}
              />
              <Route
                path="/settings/seller/:contract_id"
                element={<SellerProfileView />}
              />
              {/* THANAWI TRANSFORMATION ROUTES ENDED */}
            </Route>
            <Route path="/register" element={<RegistrationPage />} />
            <Route
              path="/registrationDetails"
              element={<RegistrationDetails />}
            />
            <Route path="/otp-verify" element={<OtpForm />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route path="/503-no-internet" element={<NoInternetConnection />} />
            <Route path="/404-no-page" element={<NoPageFound />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/catagory-slider" element={<CatagorySlider />} />
            <Route path="/nda" element={<NDA />} />
            <Route
              path="/subscription-package"
              element={<MonthlySubscriptionPackage />}
            />
            <Route path="/user-profile" element={<MyProfile />} />
            <Route path="/user-edit-profile" element={<EditProfile />} />
            <Route path="/privacy-policy" element={<PolicyPage />} />
            <Route path="/terms-condition" element={<TermsConditionsPage />} />
            <Route path="/contact-us" element={<ContactUsPage />} />
            <Route path="/faq" element={<FaqPage />} />
            <Route path="/version" element={<>{"v4.0.6"}</>} />
            <Route path="/ping" element={<PingPage />} />
          </Routes>
        </AuthGuard>
      </Suspense>
    </div>
  );
}

export default App;
